<!--用户-->
<template>
  <div class="panel userList">
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form" size="mini">
        <el-form-item label="Enterprise User" size="mini">
          <template>
            <el-input
              v-model.trim="form.pin"
              placeholder="please enter Enterprise User"
              size="mini"
              maxlength="100"
            />
          </template>
        </el-form-item>
        <el-form-item label="Enterprise Name:" size="mini">
          <template>
            <el-select v-model="enterpriseNameCode" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseFn">
              <el-option
                v-for="item in EnterpriseList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Contract No" size="mini">
          <template>
            <el-input
              v-model.trim="form.contractNo"
              size="mini"
              placeholder="please enter Contract No"
              maxlength="100"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                style="width:30px"
                @click="showSku"
              />
            </el-input>
          </template>
        </el-form-item>
        <el-form-item label="User Flag" size="mini">
          <template>
            <el-select
              v-model.trim="form.userType"
              placeholder="Please Choose"
              size="mini"
              @change="change()"
            >
              <el-option
                v-for="item in UserFlagList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item
          label="Validity Type:"
          size="mini"
          style="margin-left: 40px"
        >
          <template>
            <el-select
              v-model.trim="form.state"
              placeholder="Please Choose"
              size="mini"
            >
              <el-option
                v-for="item in ValidityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
      </el-form>
      <div style="padding: 20px 0">
        <el-button type="" size="mini" style="width: 100px" @click="offBtn">
          Reset
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          class="search-button"
          style="width: 100px"
          @click="searchBtn"
        >
          Search
        </el-button>
        <el-button
          v-if="!unbind"
          type="primary"
          size="mini"
          style="width: 100px"
          @click="addBtn"
        >
          Mount
        </el-button>
        <el-button
          v-if="unbind"
          type=""
          size="mini"
          style="width: 100px"
          @click="$router.back()"
        >
          Back
        </el-button>
        <el-button v-if="!unbind" type="primary" size="mini" @click="editBtn">
          Modify pwd
        </el-button>
      </div>
    </div>
    <div class="panel-body">
      <el-table
        :data="tableList"
        fit
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
        @row-click="listClick"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column
          label="Enterprise User"
          min-width="140"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.pin"
              type="text"
              style="color:#2262ff;cursor:pointer"
              @click="addBtn(scope.row)"
            >
              {{ scope.row.pin }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          label="User Name"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.nickName || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="User Type"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="">
            <span>VSP</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Contract No"
          min-width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.contractNo || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="User Flag"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-for="(items, index) in UserFlagList" :key="index">
              <span v-if="items.value == scope.row.userType">{{
                items.label
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Parent User"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.parentPin || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Validity Type"
          width="130"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <i
              :ref="scope.$index"
              :class="
                scope.row.state == 0 ? 'el-icon-success' : 'el-icon-error'
              "
              :style="scope.row.state == 0 ? 'color:#40C740' : 'color:#FA5050'"
              style="margin-right: 5px"
            />
            <span>{{ scope.row.state == 0 ? 'Valid' : 'Invalid' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="unbind"
          label="Operate"
          min-width="133"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="unbindBnt(scope.row)">
              Unbind
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog title="Choice Contract No" :visible.sync="dialogSKU" width="50%">
      <Delotal @handleCurrentChanges="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入API接口
import { getFirstUserList, userUnBind } from '@/api/user/list';
import Delotal from '../contractManage/conttemplat.vue';
import { getEnterpriseName } from '@/api/enterprise/enterprise';
export default {
  // 引入局部刷新
  inject: ['reload'],
  components: {
    Delotal
  },
  data() {
    return {
      emptyImgSrc: '',
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      // 搜索条件
      form: {
        pin: '',
        contractNo: '',
        userType: '',
        state: '',
      },
      // 字典表
      UserFlagList: [
        { value: 1, label: 'Main User' },
        { value: 2, label: 'Child User' }
      ],
      ValidityList: [
        { value: 0, label: 'Valid' },
        { value: 1, label: 'Invalid' }
      ],
      // 后端获取表格信息
      tableList: [],
      // 选中列表信息项
      multipleSelection: '',
      // 定义tabel切换初始位置
      outeObject: this.$route.query,
      unbind: false,
      thisApi: process.env.VUE_APP_BASE_API,
      fileList_up: '',
      dialogSKU: false,
      multipleSelections: '',
      EnterpriseList: [],
      enterpriseNameCode: '',
    };
  },
  created() {
    // 初始化表数据
    if (!this.outeObject.contractNo || this.outeObject.contractNo == undefined) {
      this.unbind = false;
      this.getFirstData();
    } else {
      this.form.contractNo = this.outeObject.contractNo;
      this.unbind = true;
      this.getFirstData();
    }
    this.getEnterpriseNameData();
    this.emptyImgSrc = '';
  },
  methods: {
    // 切换企业名称，回显合同号
    changeEnterpriseFn(val) {
      this.form.contractNo = val;
    },
    // 根据企业名称查询 合同号
    getEnterpriseNameData() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
        }
      });
    },
    showSku() {
      this.dialogSKU = true;
    },
    handleCurrentChanges(val) {
      console.log(val);
      this.multipleSelections = val;
    },
    updateDatas() {
      console.log(this.multipleSelections);

      this.form.contractNo = this.multipleSelections.contractNo;
      this.dialogSKU = false;
    },
    successChange(file, fileList) {
      // this.batchForm.fileList.push({ name: fileList.name, url: file });
      console.log(file);
    },
    // 获取页面数据
    getFirstData() {
      this.getUserListAjax();
    },
    // ajax请求
    getUserListAjax() {
      getFirstUserList(
        Object.assign(
          {
            pageNo: this.listQuery.pageCode,
            pageSize: this.listQuery.pageSize
          },
          this.form
        )
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total = res.data.totalCount;
        } else {
          // 暂无数据
          this.tableList = [];
          this.listQuery.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 新增
    addBtn(row) {
      this.$router.push({
        name: 'User Management New/edit',
        path: '/user/add',
        query: { icon: row.id }
      });
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
      this.enterpriseNameCode = '';
    },
    // 点击行时触发
    listClick(row) {
      this.multipleSelection = row;
    },
    handleChange() {},
    // 修改按钮
    editBtn() {
      if (this.multipleSelection == '') {
        this.$confirm('Please Choose what you want to modify!', 'warning', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then(() => {})
          .catch(function() {});
      } else {
        this.$router.push({
          name: 'change password',
          path: '/user/changePassword',
          query: { cont: this.multipleSelection }
        });
      }
    },
    //解绑按钮
    unbindBnt(row) {
      this.$confirm('Whether to unbind the user', 'warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      })
        .then(() => {
          var obj = {
            pin: row.pin,
            contractNo: row.contractNo,
            userType:row.userType
          };
          userUnBind(obj).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'Unbundling success',
                type: 'success'
              });
              setTimeout(() => {
                this.$router.push({
                  name: 'contract list',
                  path: '/contractManage/contractList'
                });
              }, 1000);
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        })
        .catch(function() {});
    }
  }
};
</script>
<style lang="less" scoped>
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.userList {
  .el-input {
    width: 200px;
    margin-right: 30px;
  }

  .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }
}
.heard_box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  div {
    label {
      margin-right: 5px;
    }
  }
}
.serch_box {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.el-button {
  font-size: 14px;
  ::v-deep .el-icon-search {
    font-size: 14px;
  }
}

/deep/.el-form-item__label {
  font-size: 14px !important;
  font-family: ArialMT;
}
/deep/.el-button {
  // padding: 0 12px !important;
  //width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}

::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}

.panel-body {
  padding: 0 16px;
}
</style>
